.product {
  padding: 20px 50px;
  display: flex;
  gap: 50px;

  .left {
    flex: 1;
    display: flex;
    gap: 20px;
    .images {
      flex: 1;

      img{
        width: 100%;
        height: 150px;
        object-fit: cover;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
    .mainImg {
      flex: 5;

      img{
        width: 100%;
        max-height: 600px;
        object-fit: cover;
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .price{
      font-size: 1.5em;
      color: #2879fe;
      font-weight: 500;
    }

    p{
      font-size: 1em;
      font-weight: 300;
      text-align: justify;
    }

    .add{
      width: 250px;
      padding: 10px;
      background-color: #2879fe;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      cursor: pointer;
      border: none;
      font-weight: 500;
    }

    .links{
      display: flex;
      gap: 20px;

      .item{
        display: flex;
        align-items: center;
        gap: 10px;
        color: #2879fe;
        font-size: 1em;
      }
    }

    .info{
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: gray;
      font-size: 1em;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;

    .left, .right {
      flex: none;
    }

    .left {
      .images, .mainImg {
        flex: none;
      }
    }

    .right {
      .add {
        width: 100%;
      }
    }
  }
}
