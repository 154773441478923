.Banner {
  background-color: #f5f5f5;
  max-width: 100%;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .red {
    color: orange;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1500px;
  }
  .Banner-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .Banner-content-span {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .Banner-content-title {
    font-size: 3.5rem;
    font-weight: 600;
  }
  .banner-img {
    width: 350px;
    height: 100%;
    border-radius: 50px;
  }
}
