.footer {
  margin: 100px 200px 20px 200px;

  .top {
    display: flex;
    gap: 50px;

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: justify;
      font-size: 14px;

      h1 {
        font-size: 18px;
        font-weight: 500;
        color: #555;
      }
      ul {
        list-style: none;
        text-decoration: none;
        padding: 0;
        margin: 0;

        .link {
          text-decoration: none;
        }
        li {
          margin-bottom: 5px;
          max-width: 110px;
          color: gray;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            color: orange;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
          }
        }
      }
    }
    .item .span {
      font-size: 0.7rem;
      color: gray;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    .left {
      display: flex;
      align-items: center;

      .logo {
        color: #2879fe;
        font-weight: bold;
        font-size: 24px;

        .link {
          text-decoration: none;
        }
        &:hover {
          color: orange;
        }
      }

      .copyright {
        margin-left: 20px;
        font-size: 12px;
        color: gray;
      }
    }
    .right {
      img {
        height: 50px;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 50px 20px;

    .top {
      flex-direction: column;
      gap: 20px;

      .item {
        text-align: left;
      }
    }

    .bottom {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      .left,
      .right {
        margin-bottom: 20px;
      }

      .left {
        .copyright {
          margin-left: 0;
        }
      }

      .right {
        img {
          height: 30px;
        }
      }
    }
  }
}
