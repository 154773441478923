.card {
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  .image {
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    &:hover {
      .secondImg {
        z-index: 2;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    .mainImg {
      z-index: 1;
    }

    span {
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: white;
      color: teal;
      padding: 3px 5px;
      z-index: 3;
      font-weight: 500;
      font-size: 12px;
    }
  }

  .product-title {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
  .prices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    width: 100%;

    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
    }
    .old-price {
      background-color: gold;
      border-radius: 30px;
      padding: 2px;
      color: gray;
      text-decoration: line-through;
      font-size: medium;
      font-weight: 400;
    }
    .new-price {
      font-size: medium;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 100%;
    }
  }

  @media (max-width: 860px) {
    width: 100%;
    margin-bottom: 20px;

    .image {
      height: 200px;
    }

    h2 {
      font-size: 14px;
    }

    .prices {
      h3 {
        font-size: 16px;
      }
    }
  }
}
