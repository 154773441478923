.navbar {
  margin: 0 auto;
  max-width: 1550px;
  height: 80px;

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 25px;

      .menuIcon {
        display: none;
        cursor: pointer;
      }

      .menuItems {
        display: flex;
        justify-content: space-between;
        gap: 25px;

        &.active {
          display: flex;
        }
      }
    }
    .center {
      font-size: 30px;
      letter-spacing: 2px;
    }
    .right {
      display: flex;
      align-items: center;
      gap: 25px;

      .icons {
        display: flex;
        gap: 15px;
        color: #777;
        cursor: pointer;

        .cartIcon {
          position: relative;

          span {
            font-size: 12px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #2879fe;
            color: white;
            position: absolute;
            right: -10px;
            top: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      flex-direction: row;
      justify-content: space-between;

      .item {
        font-size: 16px;
        justify-content: space-around;
      }

      .left,
      .right {
        gap: 15px;
      }

      .left {
        .menuIcon {
          display: block;
        }

        .menuItems {
          display: none;

          &.active {
            display: block;
          }
        }
      }

      .center {
        display: none;
      }

      .links {
        display: none;
      }

      .right {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
}
