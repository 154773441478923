.categories {
  margin-bottom: 100px;
  background-color: rgb(254, 225, 170);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .container {
    padding: 20px;
    max-width: 1400px;
    max-height: 500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.02);
    }
  }

  .row {
    flex: 1;
    display: flex;
    gap: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .link {
      position: absolute;
      min-width: 50px;
      width: fit-content;
      text-align: center;
      height: 20px;
      padding: 10px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      border: none;
      background-color: teal;
      color: white;
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 20px;

      &:hover {
        background-color: orange;
      }
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: 300px;
    gap: 5px;
    margin: 5px;

    .col {
      gap: 5px;
    }

    .row {
      gap: 5px;

      button {
        min-width: 80px;
        height: 40px;
        padding: 5px;
      }
    }
  }
}
