.products {
  padding: 30px 0;
  display: flex;
  max-width: 1400px;
  margin: 0 auto;

  .left {
    flex: 1;
    position: sticky;
    height: 100%;
    top: 50px;

    .filterItem {
      margin-bottom: 30px;

      h2 {
        font-weight: 400;
        margin-bottom: 20px;
      }

      .inputItem {
        margin-bottom: 10px;
        label {
          margin-left: 10px;
        }
      }
    }
  }

  .right {
    flex: 3;

    .catImg {
      width: 100%;
      height: 300px;
      object-fit: cover;
      margin-bottom: 50px;
    }

    .pagination {
      display: flex;
      gap: 20px;
      justify-content: center;
      margin-top: 50px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .left,
    .right {
      flex: none;
    }

    .left {
      position: relative;
      top: 0;
      margin-bottom: 30px;
    }

    .right {
      .catImg {
        height: auto;
      }
    }
  }
}
