.featuredProducts {
  max-width: 1400px;
  margin: 0 auto;
  margin-block: 50px 100px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    h1 {
      flex: 2;
      text-transform: capitalize;
    }

    p {
      flex: 3;
      font-size: medium;
      color: gray;
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    gap: 50px;
  }

  @media (max-width: 768px) {
    margin: 50px 20px;

    .top {
      flex-direction: column;
      align-items: flex-start;

      h1,
      p {
        flex: auto;
      }
    }

    .bottom {
      flex-direction: column;
      gap: 20px;
    }
  }
}
